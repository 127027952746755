// @fortawesome icons
import { faTrophy } from "@fortawesome/pro-duotone-svg-icons"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
// page styles
import salsaContestAboutStyle from "assets/jss/material-kit-pro-react/views/salsaContestPageSections/salsaContestAboutStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(salsaContestAboutStyle)

export default function SalsaContestAbout() {
  const { salsaContestAbout1 } = useStaticQuery(graphql`
    query getSalsaContestAboutImages {
      salsaContestAbout1: file(
        relativePath: { eq: "salsa-contest/salsa-contest-5.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer
          justifyContent="center"
          className={classes.salsaContestInfoArea}
        >
          <GridItem xs={12} sm={10} md={8}>
            <h2 className={classes.title}>Does your salsa bring the heat?</h2>
          </GridItem>
        </GridContainer>
        <GridContainer
          justifyContent="center"
          alignItems="center"
          className={classes.salsaContestInfoAreaMiddle}
        >
          <GridItem xs={12} sm={10} md={7} className={classes.contestsTextItem}>
            <InfoArea
              className={classes.infoArea}
              title="Salsa Contest"
              description={
                <p>
                  Chilimania also holds an annual salsa contest. All registered
                  cooks are welcome to bring their homemade salsa and chips for
                  judging by a select panel of judges and Chilimania attendees.
                  <br />
                  <br />
                  Bring your own homemade salsa, provide some chips and you will
                  be eligible to win some extra cash! All salsas entered must be
                  homemade.
                  <br />
                  <br />
                  <b>
                    Note: All salsa contestants must also be registered to
                    compete in at least one of the chili cookoff categories.
                  </b>
                </p>
              }
              faIcon={faTrophy}
              iconColor="secondary"
            />
          </GridItem>
          <GridItem md={5} sm={8} xs={10} className={classes.contestsImageItem}>
            <GatsbyImage
              image={salsaContestAbout1.childImageSharp.gatsbyImageData}
              imgStyle={{ objectFit: "contain" }}
              className={classes.contestsImage}
              alt="CASI Chili Cook-off"
            />
          </GridItem>
        </GridContainer>
        <GridContainer
          justifyContent="center"
          className={classes.salsaContestAboutButtons}
        >
          <GridItem xs={12}>
            <Link to="/about">
              <Button className={classes.aboutButton} color="primary">
                More Info
              </Button>
            </Link>
            <Link to="/register">
              <Button className={classes.aboutButton} color="primary">
                Register
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
