import {
  container,
  description,
  section,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const salsaContestAboutStyle = (theme) => ({
  title: {
    ...title,
    ...textCenter,
    marginTop: "0",
  },
  description: {
    ...description,
    ...textCenter,
  },
  aboutButton: {
    margin: "1rem",
    lineHeight: "18px",
  },
  salsaContestAboutButtons: {
    ...textCenter,
  },
  salsaContestInfoArea: {
    marginBottom: "40px",
  },
  salsaContestInfoAreaMiddle: {
    marginBottom: "70px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  container,
  customIconWrapper: {
    float: "left",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "30px auto -35px auto",
      textAlign: "center",
    },
  },
  contestsImage: {
    margin: "0 auto",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  },
  contestsImageItem: {
    [theme.breakpoints.down("sm")]: {
      order: "1",
    },
  },
  contestsTextItem: {
    [theme.breakpoints.down("sm")]: {
      order: "2",
    },
  },
  contestsTextSvgItem: {
    [theme.breakpoints.down("sm")]: {
      order: "2",
      display: "flex",
      flexDirection: "column",
    },
  },
  icon: {
    width: "2.5rem !important",
    height: "2.5rem",
    fontSize: "2.5rem",
    color: "#f24231",
    transform: "scale(.7712)",
  },
  section: {
    ...section,
    padding: "80px 0px",
  },
})

export default salsaContestAboutStyle
